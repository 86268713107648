<template>
  <div class="pa-5">
    <v-card elevation="0" class="pa-5">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">บันทึกค่าใช้จ่าย</h4>
      </div>

     <v-card-title class="pt-0 px-0">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4">
            <v-text-field
              v-model="filterData.search"
              label="พิมพ์คำค้นหา"
              single-line
              hide-details
            >
              <v-icon
                slot="prepend"
                color="#182839"
              >
                mdi-magnify
              </v-icon>
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="3">
            <v-menu
              ref="menuFilterDate"
              v-model="menuFilterDate"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedFilterDateFormatted"
                  label="วันที่บันทึก"
                  placeholder="ระบุวันที่บันทึก"
                  append-outer-icon="mdi-calendar-month"
                  readonly
                  required
                  @click:append-outer="menuFilterDate = true"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="filterData.date"
                range
                no-title
                @change="menuFilterDate = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="2">
            <v-select
              :items="itemsStatus"
              v-model="filterData.status"
              item-text="name"
              item-value="name"
              label="สถานะค่าใช้จ่าย"
              placeholder="เลืือกสถานะค่าใช้จ่าย"
              no-data-text="ยังไม่มีข้อมูล"
              single-line
              hide-details
            ></v-select>
          </v-col>
          <v-spacer/>
          <div class="d-flex align-center justify-end px-3 pb-3">
            <v-btn
              :loading="loaddata"
              :disabled="loaddata"
              color="success"
              @click="addOutcomeData"
            >
              <v-icon
                left
                dark
              >
                mdi-plus-circle
              </v-icon>
              ค่าใช้จ่าย
            </v-btn>
          </div>
        </v-row>
      </v-card-title>

      <v-data-table
        :headers="headers"
        :items="data"
        :loading="loaddata"
        loading-text="กำลังโหลด..."
      >
      <template v-slot:item.outcome_bill_no="{ item }">{{item.outcome_bill_no || '-'}}</template>
      <template v-slot:item.outcome_date="{ item }">{{moment(item.outcome_date).add(543, 'year').format('DD/MM/YYYY')}}</template>
      <template v-slot:item.outcome_savedby="{ item }">{{item.outcome_savedby.name}}</template>
      <template v-slot:item.outcome_costprice="{ item }"><div class="text-end">{{formatThaiBaht(item.outcome_costprice)}}</div></template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mr-3"
          icon
          @click="viewservice(item)"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          icon
          @click="printservice(item)"
        >
          <v-icon>mdi-printer</v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-btn
          color="primary"
          @click="initData"
        >
          รีเซ็ต
        </v-btn>
      </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      loader: null,
      loaddata: false,
      loaderAdd: false,
      data: [{
        _id: '0000',
        no: 1,
        outcome_name: 'เงินเดือนหมอ',
        outcome_type: 'เงินเดือน',
        outcome_bill_no: '',
        outcome_date: moment(),
        outcome_savedby: {
          name: 'Admin_WG001'
        },
        outcome_costprice: 50000,
        outcome_status: 'รอชำระ'
      }, {
        _id: '0001',
        no: 2,
        outcome_name: 'เงินพนักงาน',
        outcome_type: 'เงินเดือน',
        outcome_bill_no: '',
        outcome_date: moment(),
        outcome_savedby: {
          name: 'Admin_WG001'
        },
        outcome_costprice: 20000,
        outcome_status: 'รอชำระ'
      }, {
        _id: '0002',
        no: 3,
        outcome_name: 'เครื่องเขียน',
        outcome_type: 'อุปกรณ์สำนักงาน',
        outcome_bill_no: '',
        outcome_date: moment(),
        outcome_savedby: {
          name: 'Admin_WG001'
        },
        outcome_costprice: 500,
        outcome_status: 'ยกเลิก'
      }, {
        _id: '0003',
        no: 4,
        outcome_name: 'คอมพิวเตอร์',
        outcome_type: 'อุปกรณ์สำนักงาน',
        outcome_bill_no: '',
        outcome_date: moment(),
        outcome_savedby: {
          name: 'Admin_WG001'
        },
        outcome_costprice: 15000,
        outcome_status: 'ชำระแล้ว'
      }],
      itemsStatus: [{
        _id: '00000',
        name: 'รอชำระ',
        value: 'waiting'
      }, {
        _id: '00001',
        name: 'ชำระแล้ว',
        value: 'complete'
      }, {
        _id: '00002',
        name: 'ยกเลิก',
        value: 'cancel'
      }],
      menuSelectDate: false,
      filterData: {
        date: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
        search: '',
        doctor: '',
        status: ''
      },
      menuFilterDate: false
    }
  },
  methods: {
    initData () {
      const self = this
      self.loaddata = true
      setTimeout(() => {
        self.loaddata = false
      }, 2000)
    },
    formatThaiBaht (num) {
      var p = num.toFixed(2).split('.')
      return p[0].split('').reverse().reduce((acc, num, i, orig) => {
        return num === '-' ? acc : num + (i && !(i % 3) ? ',' : '') + acc
      }, '') + '.' + p[1]
    },
    viewservice (item) {
      console.log('view: ', item)
    },
    printservice (item) {
      console.log('print: ', item)
    },
    addOutcomeData () {
      console.log('Add Outcome')
    }
  },
  computed: {
    headers () {
      return [
        {
          text: '#',
          align: 'center',
          value: 'no',
          sortable: false
        }, {
          text: 'รายการ',
          align: 'center',
          value: 'outcome_name',
          sortable: false
        }, {
          text: 'ประเภทค่าใช้จ่าย',
          align: 'center',
          value: 'outcome_type',
          sortable: false
        }, {
          text: 'เลขที่อ้างอิง',
          align: 'center',
          value: 'outcome_bill_no',
          sortable: false
        }, {
          text: 'วันที่บันทึก',
          align: 'center',
          value: 'outcome_date',
          sortable: false
        }, {
          text: 'ผู้บันทึก',
          align: 'center',
          value: 'outcome_savedby',
          sortable: false
        }, {
          text: 'จำนวน',
          align: 'center',
          value: 'outcome_costprice',
          sortable: false
        }, {
          text: 'สถานะ',
          align: 'center',
          value: 'outcome_status',
          sortable: false
        }, {
          text: 'จัดการ',
          align: 'center',
          value: 'actions',
          sortable: false
        }
      ]
    },
    computedFilterDateFormatted () {
      const self = this
      // return self.filterData.date ? moment(self.filterData.date).format('DD/MM/YYYY') : ''
      return `${self.filterData.date[0] ? moment(self.filterData.date[0]).add(543, 'year').format('DD/MM/YYYY') : moment().add(543, 'year').format('DD/MM/YYYY')} ถึง ${self.filterData.date[1] ? moment(self.filterData.date[1]).add(543, 'year').format('DD/MM/YYYY') : moment(self.filterData.date[0]).add(543, 'year').format('DD/MM/YYYY')}`
    }
  }
}
</script>
