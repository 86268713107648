var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5"},[_c('v-card',{staticClass:"pa-5",attrs:{"elevation":"0"}},[_c('div',{staticClass:"page-title-box d-flex align-items-center justify-content-between"},[_c('h4',{staticClass:"mb-0"},[_vm._v("บันทึกค่าใช้จ่าย")])]),_c('v-card-title',{staticClass:"pt-0 px-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4"}},[_c('v-text-field',{attrs:{"label":"พิมพ์คำค้นหา","single-line":"","hide-details":""},model:{value:(_vm.filterData.search),callback:function ($$v) {_vm.$set(_vm.filterData, "search", $$v)},expression:"filterData.search"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"#182839"},slot:"prepend"},[_vm._v(" mdi-magnify ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3"}},[_c('v-menu',{ref:"menuFilterDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.computedFilterDateFormatted,"label":"วันที่บันทึก","placeholder":"ระบุวันที่บันทึก","append-outer-icon":"mdi-calendar-month","readonly":"","required":""},on:{"click:append-outer":function($event){_vm.menuFilterDate = true}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFilterDate),callback:function ($$v) {_vm.menuFilterDate=$$v},expression:"menuFilterDate"}},[_c('v-date-picker',{attrs:{"range":"","no-title":""},on:{"change":function($event){_vm.menuFilterDate = false}},model:{value:(_vm.filterData.date),callback:function ($$v) {_vm.$set(_vm.filterData, "date", $$v)},expression:"filterData.date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"2"}},[_c('v-select',{attrs:{"items":_vm.itemsStatus,"item-text":"name","item-value":"name","label":"สถานะค่าใช้จ่าย","placeholder":"เลืือกสถานะค่าใช้จ่าย","no-data-text":"ยังไม่มีข้อมูล","single-line":"","hide-details":""},model:{value:(_vm.filterData.status),callback:function ($$v) {_vm.$set(_vm.filterData, "status", $$v)},expression:"filterData.status"}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center justify-end px-3 pb-3"},[_c('v-btn',{attrs:{"loading":_vm.loaddata,"disabled":_vm.loaddata,"color":"success"},on:{"click":_vm.addOutcomeData}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus-circle ")]),_vm._v(" ค่าใช้จ่าย ")],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"loading":_vm.loaddata,"loading-text":"กำลังโหลด..."},scopedSlots:_vm._u([{key:"item.outcome_bill_no",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.outcome_bill_no || '-'))]}},{key:"item.outcome_date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.outcome_date).add(543, 'year').format('DD/MM/YYYY')))]}},{key:"item.outcome_savedby",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.outcome_savedby.name))]}},{key:"item.outcome_costprice",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.formatThaiBaht(item.outcome_costprice)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"icon":""},on:{"click":function($event){return _vm.viewservice(item)}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.printservice(item)}}},[_c('v-icon',[_vm._v("mdi-printer")])],1)]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initData}},[_vm._v(" รีเซ็ต ")])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }